<template>
  <v-overlay
    :value="show"
    :z-index="zIndex"
  >
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="prevent-trigger-update"
      color="#333333"
    >
      <div class="d-flex justify-end py-3 px-5">
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="document-guarantee-viewer">
        <no-guarantee-reasons
          :search="search"
          @select="onSelect"
        />
      </div>
    </v-card>
  </v-overlay>
</template>
<script>

import modalWindowMixin from "@/mixins/modal-window.mixin";
import {catalogValueSelected} from '@/events/statistics'
import NoGuaranteeReasons from '../no-guarantee-reasons.vue';
import catalogModalMixin from "@/mixins/catalog-modal.mixin";
export default {
  components: {
    NoGuaranteeReasons
  },
  mixins: [modalWindowMixin, catalogModalMixin],
  methods:{
    onSelect(code){
      catalogValueSelected.trigger({code, id:this.id, field:this.field}, this.field)
      this.close()
    }
  }
};
</script>
<style scoped>
.document-guarantee-viewer {
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}
</style>
